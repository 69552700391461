<template>
  <transition-group
    name="slide-bottom-fade"
    tag="div"
    v-if="suppliers.length > 0 || !isLoading"
    class="supplier-list"
  >
    <div class="result-count" key="count">{{ resultatsCount }}</div>

    <SupplierCard
      v-for="supplier in suppliers"
      :contact="supplier"
      :key="supplier.id"
      :showLocation="showLocation"
      :id="supplier.id"
    />
  </transition-group>

  <div v-else class="empty-supplier-list">
    <div class="empty-supplier-list-content"></div>
  </div>
</template>

<script>
import SupplierCard from '../components/SupplierCard'

export default {
  name: 'SupplierList',
  components: {
    SupplierCard,
  },
  props: {
    suppliers: {
      type: Array,
      default() {
        return []
      },
    },
    showLocation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
    }
  },
  updated() {
    this.isLoading = false
  },
  computed: {
    resultatsCount() {
      return this.suppliers.length > 1
        ? this.suppliers.length + ' résultats'
        : this.suppliers.length + ' résultat'
    },
  },
}
</script>
