<template>
  <div
    class="custom-select"
    :tabindex="tabindex"
    :dataId="inputId"
    v-click-away="onClickAway"
    @click="populate"
  >
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ selected }}
    </div>
      <span class="clear" @click="clearField" v-if="selected != options[0]">X</span>
    <div
      class="searchfield"
      v-if="options.length > 0"
      :class="{ selectHide: !open }"
    >
      <input
        type="text"
        placeholder="Rechercher..."
        @input="onKeyUp"
        autocomplete="off"
        v-model="searchInput"
      />
    </div>
    <div v-if="options.length > 0" class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of this.filteredList"
        :key="i"
        @click="
          selected = option;
          open = false;
          this.searchInput = '';
          $emit('input', [inputId, selected])
        "
        :class="{ activated: option === selected }"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as VueClickAway } from 'vue3-click-away'

export default {
  name: 'SelectField',
  mixins: [VueClickAway],
  props: {
    inputId: {
      type: String,
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
      searchInput: '',
      filteredList: [],
    }
  },
  mounted() {
    //this.$emit('input', [this.inputId, this.selected])
  },
  methods: {
    populate() {
      this.filteredList = this.options
    },
    onClickAway() {
      this.searchInput = ''
      this.open = false
    },
    clearField() {
      this.selected = this.options[0]
      this.searchInput = ''
      this.open = false
      this.$emit('input', [this.inputId, this.selected])
    },
    filterOptions() {
      this.filteredList = this.options
      if (this.searchInput !== '')
        this.filteredList = this.filteredList.filter(
          (item) =>
            item.toLowerCase().search(this.searchInput.toLowerCase()) != -1
        )
    },
    onKeyUp() {
      this.filterOptions()
    },
  },
}
</script>
