<template>
  <div class="profile template sidebar-template">
    <div class="sidebar">
      <div class="password-content">
        <h3><span class="lnr lnr-user"></span>Mot de passe oublié ?</h3>
        <form @submit.prevent="forgotPaswword">
          <input
            type="text"
            placeholder="Entrez votre email"
            name="email"
            v-model="username"
          />
          <input type="submit" value="Réinitialiser mon mot de passe" />
        </form>
        <br />
        <router-link to="/" class="btn btn-primary">retour</router-link>
      </div>
      <Links />
    </div>
    <div class="content">
      <div class="illu-profile"></div>
    </div>
  </div>
</template>
<script>
import Links from '@/components/Links.vue'
import NProgress from 'nprogress'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ForgotPassword',
  components: {
    Links,
  },
  data() {
    return {
      username: '',
    }
  },
  computed: mapState({
    user: (state) => state.user?.user,
  }),
  methods: {
    ...mapActions(['user/forgotPassword']),
    async forgotPaswword() {
      NProgress.start()

      await this['user/forgotPassword'](this.username)
        .catch((e) => {
          console.log('error', e)
        })
        .then((response) => {
          console.log('updated', response)

          console.log('sent')
        })
      NProgress.done()
    },
  },
}
</script>
