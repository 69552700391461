import SupplierService from '@/services/SupplierService.js'

export const namespaced = true

export const state = {
  suppliers: [],
  activites: [],
  competences: [],
}

export const mutations = {
  SET_SUPPLIERS(state, suppliers) {
    state.suppliers = suppliers
  },
  SET_ACTIVITIES(state, activites) {
    state.activites = activites
  },
  SET_COMPETENCES(state, competences) {
    state.competences = competences
  },
  SET_DEPARTEMENTS(state, departements) {
    state.departements = departements
  }
}

export const actions = {
  async fetchSuppliers({ commit }, search) {
    return SupplierService.getSuppliers(search)
      .then((response) => {
        
        if (response.data.success == true && response.data.statusCode == 200) {
          commit('SET_SUPPLIERS', response.data.data.fournisseurs)
          commit('SET_ACTIVITIES', response.data.data.activites)
          commit('SET_COMPETENCES', response.data.data.competences)
          commit('SET_DEPARTEMENTS', response.data.data.departements)
          return response.data.data
        }
        else {
          const notification = {
            type: 'error',
            message: 'Fetching Suppliers failed'
          }
          this.dispatch('notifications/add', notification, { root: true })
          return response
        }

      })
      .catch((error) => {
        const notification = {
          type: 'error',
          message: 'Fetching Suppliers failed ' + error.message
        }
        this.dispatch('notifications/add', notification, { root: true })
        return
      })
  },

  async autocomplete({ context }, text) {
    context //Hack
    return SupplierService.autocomplete(text)
      .then((response) => {
        
        if (response.data.success == true && response.data.statusCode == 200) {
          return response.data.data
        }
        else {
          const notification = {
            type: 'error',
            message: 'Autocomplete failed'
          }
          this.dispatch('notifications/add', notification, { root: true })
          return

        }

      })
      .catch((error) => {
        const notification = {
          type: 'error',
          message: 'Autocomplete failed ' + error.message
        }
        this.dispatch('notifications/add', notification, { root: true })
        return
      })
  }
}
