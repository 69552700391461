<template>
  <header>
    <span to="/main" class="logo-dalkia" @click="onHome"></span>
    <div class="logo-guide"></div>
    <UserMenu />
  </header>

  <router-view v-slot="{ Component }">
    <transition name="slide-bottom-fade" mode="in-out">
      <component :is="Component" />
    </transition>
  </router-view>
  <NotificationContainer />
</template>

<script>
import UserMenu from '@/components/UserMenu.vue'
import NotificationContainer from '@/components/NotificationContainer.vue'
import NProgress from 'nprogress'
import { mapActions } from 'vuex'
import router from '@/router'

export default {
  name: 'App',
  components: {
    UserMenu,
    NotificationContainer,
  },
  methods: {
    ...mapActions(['user/autoLogUser']),
    onHome() {
      if (this.$route.name === 'Main') {
        router.go()
      } else router.push('/Main')
    },
  },

  async created() {
    NProgress.configure({ showSpinner: false })
    const userString = localStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString)
      await this['user/autoLogUser'](userData)
    }
  },
}
</script>

<style lang='scss'>
@import '@/assets/scss/_commons.scss';
@import '@/assets/scss/main.scss';
</style>