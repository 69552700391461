<template>
  <div class="profile template sidebar-template">
    <div class="sidebar">
      <div class="password-content">
        <h3><span class="lnr lnr-user"></span>Mot de passe</h3>
        <form @submit.prevent="updatePassword">
          <input
            type="password"
            placeholder="Ancien mot de passe"
            name="old-password"
            v-model="oldPassword"
          />
          <input
            type="password"
            placeholder="Nouveau mot de passe"
            name="new-password"
            v-model="newPassword"
          />
          <input
            type="password"
            placeholder="Confirmer nouveau mot de passe"
            name="confirm-password"
          />
          <input type="submit" value="changer de mot de passe" />
        </form>
        <br />
        <router-link to="/profile" class="btn btn-primary">retour</router-link>
      </div>
      <Links />
    </div>
    <div class="content">
      <div class="illu-profile"></div>
    </div>
  </div>
</template>
<script>
import Links from '@/components/Links.vue'
import { mapState, mapActions } from 'vuex'
import NProgress from 'nprogress'

export default {
  name: 'Password',
  components: {
    Links,
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
    }
  },
  methods: {
    ...mapActions(['user/updatePassword']),
    async updatePassword() {
      NProgress.start()
      let credentials = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      }
      await this['user/updatePassword'](credentials).then((response) => {
        console.log('updated', response)
      })
      NProgress.done()
    },
  },
  computed: mapState({
    user: (state) => state.user?.user,
  }),
}
</script>
