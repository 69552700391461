<template>
  <div class="notification-bar" :class="notificationTypeClass">
    <p>{{ notification.message }}</p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'NotificationBar',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
    }
  },
  mounted() {
    this.timeout = setTimeout(() => this.remove(this.notification), this.notification.timeout)
  },
  beforeUnmount() {
    clearTimeout(this.timeout)
  },
  computed: {
    notificationTypeClass() {
      return `notification-${this.notification.type}`
    },
  },
  methods: mapActions('notifications', ['remove']),
}
</script>
