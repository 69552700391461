<template>
  <div class="links">
    <span>© 2021 Dalkia</span>
    <span><router-link to="/page/2610">Mentions légales</router-link></span>
    <span><router-link to="/page/3">RGPD</router-link></span>
    <span><a href="https://mail.google.com/mail/u/0/?fs=1&to=service-achats-centre-est@dalkia.fr&tf=cm" target="_blank">Contact</a></span>
    <span
      ><a
        v-if="loggedIn"
        target="_blank"
        href="https://drive.google.com/drive/folders/1aNY88R55do0AfK6fsTWmQKhul36oBl8R"
        >Evaluations Fournisseurs</a
      ></span
    >
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Links',
  computed: mapState({
    loggedIn: (state) => !!state.user?.user,
  }),
}
</script>
