<template>
  <div class="profile template sidebar-template">
    <div class="sidebar">
      <div class="profile-content">
        <h3><span class="lnr lnr-user"></span>Mon profil</h3>
        <div class="profile-id">User ID : {{ user.id }}</div>
        <router-link to="/password" class="btn btn-primary"
          >changement de mot passe</router-link
        >
        <button class="logout btn btn-primary" v-if="loggedIn" @click="logout">
          <span class="lnr lnr-power-switch"></span> Se déconnecter
        </button>
        <router-link to="/main" class="btn btn-primary">retour</router-link>
      </div>
      <Links />
    </div>
    <div class="content">
      <div class="illu-profile"></div>
    </div>
  </div>
</template>
<script>
import Links from '@/components/Links.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Profile',
  components: {
    Links,
  },
  methods: {
    ...mapActions(['user/logoutUser']),
    createUser() {
      this.$emit('createUser')
    },
    logout() {
      this['user/logoutUser']()
    },
  },
  computed: mapState({
    user: (state) => state.user?.user,
    loggedIn: (state) => !!state.user?.user,
  }),
}
</script>
