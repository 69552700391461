<template>
  <div id="popin_cgv" class="popin">
    <div class="popin-content">
      <h4>Protection des données à caractère personnel</h4>
      <div class="text">
        Merci de lire et d'accepter les mentions relatives aux DCP avant
        d'utiliser le guide d'achat Dalkia
      </div>
      <router-link to="/page/3" class="link">Lire les DCP</router-link>
      <div class="btn btn-secondary" @click="acceptCGU">Accepter</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Popin',
  methods: {
    ...mapActions(['user/acceptCGU']),
    acceptCGU() {
      this['user/acceptCGU'](true)
    },
  },
}
</script>
