<template>
  <div class="home template sidebar-template">
    <Popin v-if="showPopin" />
    <div class="sidebar">
      <SearchForm
        @filtering="this.onFiltering"
        @search="this.onSearch"
        @autocomplete="this.onAutocomplete"
        :autocomplete_list="autocomplete_list"
        :filters_list="filters_list"
      />
    </div>
    <div class="content">
      <SupplierList
        :suppliers="filteredList"
        :showLocation="locationActivated"
      />
    </div>
    <Links />
  </div>
</template>


<script>
import SearchForm from '@/components/SearchForm.vue'
import SupplierList from '@/components/SupplierList.vue'
import Links from '../components/Links.vue'
import Popin from '../components/Popin.vue'
import { mapState, mapActions } from 'vuex'
import NProgress from 'nprogress'
import DefaultValues from '@/constants/index.js'

export default {
  name: 'Main',
  components: {
    SearchForm,
    SupplierList,
    Links,
    Popin
  },
  data() {
    return {
      filters: {
        activite: '',
        departement: '',
        certification: '',
      },
      filteredList: [],
      autocomplete_list: [],
      filters_list: {
        activites: [],
        departements: [],
        certifications: [],
      },
      locationActivated: false,
    }
  },
  computed: {
    ...mapState({
      suppliers: (state) => state.suppliers.suppliers,
      showPopin: (state) => state.user?.user?.acceptation_cgu == undefined ? false : !state.user?.user?.acceptation_cgu,
    }),
  },
  mounted() {
    setInterval(() => {
      this['user/sendActions']()
    }, 10000)
  },
  methods: {
    ...mapActions([
      'suppliers/fetchSuppliers',
      'suppliers/autocomplete',
      'user/sendActions',
    ]),
    onSearch(search) {
      NProgress.start()
      this['suppliers/fetchSuppliers'](search)
        .then((response) => {
          this.locationActivated = search.geoloc[0] > 0 && search.geoloc[1] > 0

          this.filteredList = this.filterList()
          response.activites = response.activites.filter((item) => item != null)
          response.certifications = response.certifications.filter(
            (item) => item != null
          )
          response.departements = response.departements.filter(
            (item) => item != null
          )

          this.filters_list.activites = [DefaultValues.DEFAULT_ACTIVITE].concat(
            response.activites
          )
          this.filters_list.certifications = [
            DefaultValues.DEFAULT_CERTIFICATION,
          ].concat(response.certifications)

          this.filters_list.departements_name = []
          this.filters_list.departements_code = []

          response.departements.filter((dep) => {
            let depArray = dep.split('|')
            this.filters_list.departements_code.push(depArray[0])
            this.filters_list.departements_name.push(depArray[1])
          })

          this.filters_list.departements = [
            DefaultValues.DEFAULT_DEPARTEMENT,
          ].concat(this.filters_list.departements_name)
          NProgress.done()
        })
        .catch((error) => {
          console.log('error: ', error)
          NProgress.done()
        })
    },
    onAutocomplete(text) {
      this.autocomplete_list = []
      if (text.length > 2) {
        this['suppliers/autocomplete'](text)
          .then((data) => {
            if (data) this.autocomplete_list = data
            else this.autocomplete_list = []
          })
          .catch((error) => {
            this.autocomplete_list = []
            console.log('error: ', error)
          })
      }
    },
    onFiltering(filters) {
      this.filters = filters
      this.filteredList = this.filterList()
    },
    filterList() {
      return this.suppliers?.filter((supplier) => {
        if (
          this.filters.activite != '' &&
          this.filters.activite != DefaultValues.DEFAULT_ACTIVITE &&
          this.filters.activite != null &&
          supplier.activite?.name != this.filters.activite
        )
          return false

        if (
          this.filters.certification != '' &&
          this.filters.certification != DefaultValues.DEFAULT_CERTIFICATION &&
          this.filters.certification != null &&
          !supplier.certification?.some((cert) => {
            return cert.name == this.filters.certification
          })
        )
          return false

        if (
          this.filters.departement != '' &&
          this.filters.departement != DefaultValues.DEFAULT_DEPARTEMENT &&
          this.filters.departement != null
        ) {
          let index = this.filters_list.departements_name.indexOf(
            this.filters.departement
          )
          let code = this.filters_list.departements_code[index]
          let found = supplier.departement?.some((dep) => {
            return dep == code
          })

          return found
        }
        return true
      })
    },
  },
}
</script>