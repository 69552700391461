<template>
  <transition name="fade">
    <div class="user-menu" v-if="loggedIn">
      <router-link to="/profile" class="profile-link">
        <div class="lnr lnr-user icon-user"></div>
        <div class="username">{{ user?.displayName }}&nbsp;</div>
        <!--<div class="lnr lnr-chevron-down icon-down"></div>-->
      </router-link>
      <div class="logout" id="logout" v-if="loggedIn" @click="logout">
        <span class="lnr lnr-power-switch"></span>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'UserMenu',
  methods: {
    ...mapActions(['user/logoutUser']),
    logout() {
      this['user/logoutUser']()
    },
  },
  computed: mapState({
    user: (state) => state.user?.user,
    loggedIn: (state) => !!state.user?.user,
  }),
}
</script>