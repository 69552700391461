<template>
  <div class="input-location">
    <input
      type="text"
      :name="name"
      :class="className"
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @keyup="onKeyUp"
      autocomplete="off"
    />
    <div
      v-if="hasLocation"
      class="input-icon location"
      :class="{ active: this.locationActivated, loading: this.loading }"
      @click="toggleLocation"
    >
      <span v-if="this.loading" class="lnr lnr-sync loading-icon"></span>
      <span v-else class="lnr lnr-map-marker map-icon"></span>
    </div>
    <transition-group name="fade" tag="span">
      <div
        class="autocomplete"
        v-if="this.showAutocomplete"
        v-click-away="onClickAway"
      >
        <ul>
          <li
            v-for="item in autocomplete_list"
            :key="item"
            @click="completeField(item)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mixin as VueClickAway } from 'vue3-click-away'
import { mapActions } from 'vuex'

export default {
  name: 'SearchInput',
  mixins: [VueClickAway],
  data() {
    return {
      locationActivated: false,
      loading: false,
      coords: {},
      showAutocomplete: false,
    }
  },
  props: {
    placeholder: {
      type: String,
      default: String,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    autocomplete_list: {
      type: Array,
      default() {
        return []
      },
    },
  },
  mounted() {
    this.toggleLocation()
  },
  computed: {
    hasLocation() {
      return navigator?.geolocation
    },
  },
  methods: {
    ...mapActions(['notifications/add']),
    toggleLocation() {
      if (this.hasLocation) {
        if (!this.locationActivated && !this.loading) {
          this.loading = true

          var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
          navigator.geolocation.getCurrentPosition(
            this.gotLocation,
            this.errorLocation,
            options
          )
          return
        }

        this.locationActivated = false
        this.$emit('location', { latitude: null, longitude: null })
      }
    },
    gotLocation(location) {
      this.loading = false
      this.locationActivated = true
      this.coords = location.coords
      this.$emit('location', this.coords)
    },
    errorLocation(error) {
      this.loading = false
      this.locationActivated = false
      console.log('error:', error)
      let message =
        error.code == 3
          ? "Veuillez activer la géolocalisation au niveau de l'OS"
          : "Erreur d'activation de la géolocalisation"
      const notification = {
        type: 'error',
        message: message,
      }
      this['notifications/add'](notification)
    },
    onKeyUp() {
      this.showAutocomplete = this.modelValue.length > 2
      this.$emit('autocomplete', this.modelValue)
    },
    completeField(item) {
      this.showAutocomplete = false
      this.$emit('fillField', item)
    },
    onClickAway() {
      this.showAutocomplete = false
    },
  },
}
</script>

<style type="css">
.location {
  border-left: 2px solid #e0ebf6;
  cursor: pointer;
  background: #fff;
  font-size: 23px;
  color: #0d5eb8;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s, color 0.3s, border 0.3s;
}
.location:hover {
  background-color: #fff1ec;
  border-left: 2px solid #fff1ec;
  color: #fd471c;
  transition: background 0.3s, color 0.3s, border 0.3s;
}
.active,
.active:hover {
  background-color: #fd471c;
  border-left: 2px solid #fd471c;
  color: white;
  transition: background 0.3s, color 0.3s, border 0.3s;
}
input {
  width: calc(100% - 50px);
}

.autocomplete {
  position: absolute;
  z-index: 1;
  width: 100%;
  min-height: 50px;
  height: max-content;
  background: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  top: 55px;
  left: 0;
  overflow-y: auto;
  border: 1px solid grey;
  border-top: none;
}
.autocomplete ul {
  padding: 0;
  margin: 10px 0 0;
  text-align: left;
}
.autocomplete li {
  color: black;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  padding: 10px;
  margin: 0;
  cursor: pointer;
}
.autocomplete li:hover {
  background: #0d5eb8;
  color: white;
}
</style>