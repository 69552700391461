<template>
  <div class="card supplier-card" :id="id">
    <div class="supplier-infos">
      <span class="astreinte-label" v-if="contact.astreinte">astreinte</span>
      <span class="astreinte-label" v-if="contact.priority"
        >SOCIETE DU GROUPE</span
      >
      <div class="infos-head">
        <span class="contact-name">
          {{ contact.name }}
          <span class="distance" v-show="canShowLocation"
            >{{ contact.geolocalisation?.distance }} km</span
          >
        </span>
        <span class="activite">{{ contact.activite.name }}</span>
      </div>
      <div class="infos-content">
        <div class="content-contact">
          <div class="supplier-logo">
            <ImageItem :source="contact.logo" />
            <!--<img v-if="contact.logo" :src="contact.logo" />
            <img v-else src="~@/assets/img/logo-default.jpg" />-->
          </div>
          <div class="contact-address">
            {{ this.zipAddress(contact) }}
            <a
              :href="this.fullAddress(contact.site_web)"
              target="_blank"
              @click="this.logAction('site')"
            >
              {{ this.trimmedWebsite(contact.site_web) }}
            </a>
            <a
              v-if="contact.fichier"
              :href="contact.fichier"
              target="_blank"
              class="btn btn-primary"
              @click="this.logAction('file')"
              >DOCUMENTATION</a
            >
            <a
              v-if="contact.evaluation"
              :href="contact.evaluation"
              target="_blank"
              class="btn btn-primary evaluation"
              @click="this.logAction('evaluation')"
              >EVALUATION</a
            >
          </div>
          <div class="contact-infos">
            <div class="contact-icon">
              <span class="lnr lnr-user icon-user"></span>
            </div>
            <div class="contact-inner">
              <p class="contact-name">{{ contact.contact_fournisseurs }}</p>
              <a
                :href="'tel:' + contact.mobile"
                @click="this.logAction('mobile')"
                >{{ contact.mobile }}</a
              >
              <a
                :href="'tel:' + contact.telephone"
                @click="this.logAction('phone')"
                >{{ contact.telephone }}</a
              >
              <a
                :href="
                  'https://mail.google.com/mail/u/0/?fs=1&to=' +
                  contact.email +
                  '&tf=cm'
                "
                @click="this.logAction('mail')"
                target="_blank"
                >{{ contact.email }}</a
              >
            </div>
          </div>
        </div>
        <div class="content-actions">
          <a
            v-if="this.isValid(contact.email)"
            :href="
              'https://mail.google.com/mail/u/0/?fs=1&to=' +
              contact.email +
              '&tf=cm'
            "
            target="_blank"
            class="mail"
            @click="this.logAction('mail')"
            ><span class="lnr lnr-envelope"></span
          ></a>
          <a
            v-if="this.isValid(contact.mobile)"
            :href="'tel:' + contact.mobile"
            class="mobile"
            @click="this.logAction('mobile')"
            ><span class="lnr lnr-smartphone"></span
          ></a>
          <a
            v-if="this.isValid(contact.telephone)"
            :href="'tel:' + contact.telephone"
            class="phone"
            @click="this.logAction('phone')"
            ><span class="lnr lnr-phone-handset"></span
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ImageItem from '@/components/ImageItem.vue'

export default {
  name: 'SupplierCard',
  components: {
    ImageItem,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    showLocation: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: -1,
    },
  },
  methods: {
    ...mapActions(['user/logAction']),
    trimmedWebsite(site_web) {
      return site_web?.replace('www.', '')
    },
    fullAddress(site_web) {
      if (site_web?.search('http') != -1) return site_web
      return 'https://' + site_web
    },
    zipAddress(contact) {
      if (contact.localisation != null)
        return contact.localisation.zip + ' ' + contact.localisation.city
      return contact.zip + ' ' + contact.city
    },
    isValid(text) {
      return text != '' && text != null
    },
    logAction(action_name) {
      let action = {
        supplier_id: this.id,
        action: action_name,
      }

      this['user/logAction'](action)
    },
    // called when target is fully visible
    onIntersection(entries, opts) {
      entries.forEach((entry) => {
        var visible = entry.intersectionRatio >= opts.thresholds[0]
        if (visible) {
          this.logAction('view')
        }
      })
    },
  },
  data() {
    return {
      observer: null,
      target: null,
      intersectionObserverOptions: {
        root: null, // default is the viewport
        threshold: 1, // percentage of the target visible area which will trigger "onIntersection"
      },
    }
  },
  computed: {
    canShowLocation() {
      return (
        this.contact != null &&
        this.contact.geolocalisation != null &&
        this.contact.geolocalisation.distance != null &&
        this.showLocation
      )
    },
  },
  mounted() {
    this.target = document.getElementById(this.id)
    if (this.target) {
      this.observer = new IntersectionObserver(
        this.onIntersection,
        this.intersectionObserverOptions
      )
      this.observer.observe(this.target)
    }
  },
  beforeUnmount() {
    if (this.target) this.observer?.unobserve(this.target)
  },
}
</script>
