<template>
  <div class="login-form">
    <h3>Connexion</h3>
    <form class="form" @submit.prevent="onSubmit">
      <input type="text" placeholder="Login" v-model="login" />
      <input type="password" placeholder="Mot de passe" v-model="password" />
      <input type="submit" value="connexion" />
    </form>
    <!--<h3>Je veux créer un compte</h3>
    <router-link to="/create-account">Créer un compte</router-link>-->
    <div class="lost-password">
      <router-link to="/forgot-password">Mot de passe oublié ?</router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import NProgress from 'nprogress'

export default {
  name: 'LoginForm',
  props: {},
  data() {
    return {
      login: '',
      password: '',
    }
  },
  methods: {
    ...mapActions(['user/logUser', 'user/checkUser']),
    async onSubmit() {
      NProgress.start()
      await this['user/logUser']({
        username: this.login,
        password: this.password,
      })
      .then((response) => {
        this.$emit('login', response)
        NProgress.done()
      })
      .catch(() => {
        this.$emit('login', false)
      })
    },
  },
}
</script>
