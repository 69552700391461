<template>
  <div class="login template login-template">
    <div class="content">
      <LoginForm @login="onLogin" />
      <Links />
    </div>
    <div class="illu">
      <div class="illu-home"></div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import LoginForm from '@/components/LoginForm.vue'
import Links from '@/components/Links.vue'
import router from '@/router'

export default {
  name: 'Login',
  components: {
    LoginForm,
    Links,
  },
  methods: {
    onLogin(loggedIn) {
      if(loggedIn) router.push({ path: '/main' })
    },
  },
}
</script>
