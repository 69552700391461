<template>
  <div class="notification-container">
    <transition-group name="fade" tag="span">
      <NotificationBar
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
      />
    </transition-group>
  </div>
</template>

<script>
import NotificationBar from '@/components/NotificationBar.vue'
import { mapState } from 'vuex'
export default {
  components: { NotificationBar },
  name: 'NotificationContainer',
  computed: mapState('notifications', ['notifications']),
}
</script>

<style scoped>
.notification-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
}
</style>

