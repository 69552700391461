import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Main from '../views/Main.vue'
import Profile from '../views/Profile.vue'
import Password from '../views/Password.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import Page from '../views/Page.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/main',
    name: 'Main',
    component: Main,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/password',
    name: 'Password',
    component: Password,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/page/:id',
    name: 'Page',
    component: Page,
    props: true,
    meta: {
      requiresAuth: false
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    console.log('not logged in');
    next('/')
  }
  else if (to.path === '/' && loggedIn)
    next('/main')
  else next()
})

export default router
