import axios from 'axios'
import DefaultValues from '@/constants/index.js'

const api = axios.create({
  baseURL: DefaultValues.BASE_URL+'/guideachat',
  headers: {
    Accept: 'application/json'
  }
})

export default {
  getSuppliers(search) {
    let user = JSON.parse(localStorage.getItem('user'))
    let headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${user.token}`,
    }
    
    return api.post('/search_supplier/', {
      search: search.search,
      astreinte: search.astreinte ? 1 : 0,
      geoloc: search.geoloc[0]+','+search.geoloc[1]

    }, { headers })
  },
  autocomplete(text) {
    
    let user = JSON.parse(localStorage.getItem('user'))
    let headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${user.token}`,
    }
    return api.post('/autocompletion_search/', {
      search: text
    }, { headers })
  }
}
