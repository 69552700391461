<template>
  <figure v-lazyload class="image__wrapper">
    <!--<ImageSpinner
      class="image__spinner"
    />-->
    <img
      class="image__item"
      :data-url="source"
      alt=""
    >
  </figure>
</template>

<script>
import LazyLoadDirective from "@/directives/LazyLoadDirective.js";

export default {
  name: 'ImageItem',
  props: {
    source: {
      type: String,
      required: true,
      default: 'https://pre-prod.leguideachats.com/admin/wp-content/uploads/2021/03/logo-default.jpeg',
    },
  },
  directives: {
    lazyload: LazyLoadDirective
  },
}
</script>
<style scoped>
.image__wrapper {
  margin: 0;
  display: flex;
}
</style>
