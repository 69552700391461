<template>
  <form class="search-form" @submit.prevent="this.searchSuppliers">
    <div class="main-form">
      <h3><span class="lnr lnr-magnifier"></span>Je recherche...</h3>
      <SearchInput
        v-model="search.search"
        name="search"
        placeholder="Nom fournisseur, Activité ..."
        className="search-input"
        :autocomplete_list="autocomplete_list"
        @location="onLocation"
        @autocomplete="this.onAutocomplete"
        @fillField="this.fillField"
      />
      <button type="submit" id="search-btn">
        <span class="lnr lnr-magnifier"></span>
        <span class="label">Lancer</span>
      </button>
      <div class="astreinte" v-show="showFilters">
        <input
          id="astreinte"
          type="checkbox"
          value="Astreinte"
          name="astreinte"
          v-model="search.astreinte"
        />
        <label for="astreinte">Astreinte</label>
      </div>
    </div>
    <transition-group
      name="slide-bottom-fade"
      tag="div"
      v-if="hasFilters"
      class="filters"
    >
      <div class="filters-content" v-show="showFilters" key="filters">
        <h4>J'affine ma recherche</h4>
        <SelectField
          inputId="activite"
          :options="filters_list.activites"
          :default="'Activité'"
          class="select"
          @input="inputChange"
        />
        <SelectField
          inputId="departement"
          :options="filters_list.departements"
          :default="'Département de couverture'"
          class="select"
          @input="inputChange"
        />
        <SelectField
          inputId="certification"
          :options="filters_list.certifications"
          :default="'Certification'"
          class="select"
          @input="inputChange"
        />
      </div>
      <div class="filters-trigger" @click="filterTrigger" key="filters-trigger"></div>
    </transition-group>
  </form>
</template>

<script>
import SearchInput from '@/components/forms/SearchInput.vue'
import SelectField from '@/components/forms/SelectField.vue'

export default {
  name: 'SearchForm',
  components: {
    SearchInput,
    SelectField,
  },
  props: {
    autocomplete_list: {
      type: Array,
      default() {
        return []
      },
    },
    filters_list: {
      type: Object,
      default() {
        return {
          activites: [],
          departements: [],
          certifications: [],
        }
      },
    },
  },
  data() {
    return {
      search: {
        search: '',
        astreinte: false,
        geoloc: [0, 0],
      },
      filters: {
        activite: '',
        departement: '',
        certification: '',
      },
      showFilters: false,
    }
  },
  computed: {
    hasFilters() {
      return (
        this.filters_list.activites.length > 0 ||
        this.filters_list.departements.length > 0 ||
        this.filters_list.certifications.length > 0
      )
    },
  },
  methods: {
    searchSuppliers() {
      this.$emit('search', this.search)
    },
    onLocation(coords) {
      this.search.geoloc[0] = coords.latitude
      this.search.geoloc[1] = coords.longitude
    },
    onAutocomplete(text) {
      this.$emit('autocomplete', text)
    },
    fillField(text) {
      this.search.search = text
      this.$emit('search', this.search)
    },
    filterTrigger() {
      this.showFilters = !this.showFilters
    },
    inputChange(option) {
      this.filters[option[0]] = option[1]
      this.$emit('filtering', this.filters)
    },
  },
}
</script>
